import React, { useState, useEffect } from "react";
import '../DepartmentPages/DepartmentForm.css';
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import Select from "react-select";
import {
    ASSET_PREFIX_URL,
    BASE_URL,
    WORKSPACE,
    WORKSPACE_VISIBILITY_OPTIONS,
    HEADERS,
    GOOGLE_CLIENT_ID
} from "../../constants/config";
import PopupCloseButton from "../Common/PopupCloseButton/PopupCloseButton";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import MemberDropdown from './../Common/MemberDropdown';

function CreateWorkspacePopUp({ onClose }) {
    const [workspaceName, setWorkspaceName] = useState("");
    const [description, setDescription] = useState("");
    const [teamMembers, setTeamMembers] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [fieldErrors, setFieldErrors] = useState({});
    const [uuidToken, setUuidtoken] = useState(null);
    const nav = useNavigate();
    const [loading, setLoading] = useState(false);
    

    const handlePersonSelectChange = (e, selected) => {
        setTeamMembers(selected)
    }
    const validateForm = () => {
        const errors = {};
        const nameRegex = /^[a-zA-Z0-9\s]+$/;
        if (!workspaceName) {
            errors.workspaceName = "Workspace name is required.";
        } else if (!nameRegex.test(workspaceName)) {
            errors.workspaceName = "Workspace name cannot contain special characters.";
        }

       

        if (teamMembers.length === 0) {
            errors.teamMembers = "At least one team member must be selected.";
        }
        console.log(errors)
        setFieldErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const submitForm = async (e) => {

        e.preventDefault();
        if (!validateForm()) {
            toast.error("Please fill details.");
            return;
        } if (!uuidToken) {
            toast.error('Please authorize to create workspace');
            return;
        }
        setIsSubmitting(false);

        const formData = {
            workspace_name: workspaceName,
            description,
            // visibility: visibility?.value,
            team_members: teamMembers.map((member) => member.value),
            uuid: uuidToken

        };
        console.log(formData)

        try {
            setLoading(true)
            const response = await axios.post(`${BASE_URL}/v1/pdm/create-workspace`, formData, {
                headers: HEADERS,
            });

            if (!response.data?.meta?.success) {
                setFieldErrors({ workspaceName: response.data?.meta.message });

            } else {
                onClose()
                nav(WORKSPACE(response.data.data)) // toast.success('Workspace created successfully')
            }
            setLoading(false)
        } catch (error) {
            console.error("Error submitting form:", error);
        } finally {
            setIsSubmitting(false);
        }
    };

   

    const driveAuthentication = () => {
        const redirectUri = `${BASE_URL}/v1/pdm/oauth2callback`;
    
        const getAuthUrl = () => {
            const state = JSON.stringify({ uuid: uuidv4() });
            const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?` +
                `client_id=${GOOGLE_CLIENT_ID}&` +
                `redirect_uri=${redirectUri}&` +
                `response_type=code&` +
                `scope=https://www.googleapis.com/auth/drive&` +
                `access_type=offline&` +
                `prompt=consent&` +
                `state=${encodeURIComponent(state)}`;
            return authUrl;
        };
    
        const authUrl = getAuthUrl();
        const authWindow = window.open(
            authUrl,
            "GoogleAuthPopup",
            "width=600,height=700,top=100,left=100,resizable=yes,scrollbars=yes,status=yes"
        );
    
        const handleMessage = (event) => {
            if (event.origin !== BASE_URL) return; // Validate the source
            const { uuid: returnedUUID, success, error } = event.data;
    
            if (success) {
                setUuidtoken(returnedUUID);
                console.log(`Successfully authorized with UUID: ${returnedUUID}`);
                // Show success toast
                toast.success("Successfully authorized!", "success");
            } else {
                console.error(`Authentication failed: ${error}`);
                // Show error toast
                toast.error("Authentication failed!", "error");
            }
            authWindow.close();
            // Remove the event listener
            window.removeEventListener("message", handleMessage);
        };
    
        window.addEventListener("message", handleMessage);
    };
    




    return (
        <>
            <div className="ec-form-page">
                <div className="ec-ticket-home">
                    <div className="ex-ticket-title">
                        <span className="ec-title">Create new workspace</span>
                        <PopupCloseButton handleClose={onClose} />
                    </div>
                    <div className="workspace-form-grid" style={{ marginTop: '20px', width: '100%', height: 'auto' }}>
                        <div className="grid-item">
                            <span>Workspace Name*</span>
                            <div className="type-texts">
                                <input
                                    type="text"
                                    value={workspaceName}
                                    onChange={(e) => setWorkspaceName(e.target.value)}
                                    placeholder="e.g., Battery Project Alpha"
                                />
                            </div>
                            {fieldErrors.workspaceName && (
                                <span className="error-message">
                                    <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                    {fieldErrors.workspaceName}
                                </span>
                            )}
                        </div>
                        <div className="grid-item">
                            <span>Description</span>
                            <div className="type-texts">
                                <textarea
                                    type="text"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="A short description of the workspace’s purpose or goals"
                                />
                            </div>
                        </div>
                        {/* <div className="grid-item">
                            <span>Visibility</span>
                            <div style={{ width: '370px' }}>
                                <Select
                                    options={WORKSPACE_VISIBILITY_OPTIONS}
                                    value={visibility}
                                    onChange={setVisibility}
                                    placeholder="Select visibility"
                                />

                            </div>

                            {fieldErrors.visibility && (
                                <span className="error-message">
                                    <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                    {fieldErrors.visibility}
                                </span>
                            )}
                        </div> */}
                        {/* {(visibility?.value == 'private') && ( */}
                            <div className="grid-item">
                                <span>Team Members</span>
                                <MemberDropdown field={{
                                    fieldName: 'Team Members',
                                    placeholder: 'Select members',
                                    isMulti: true,

                                }} handlePersonSelectChange={handlePersonSelectChange} />
                                {/* <Select
                                    placeholder="Add members"
                                    styles={customStyles}
                                    menuPortalTarget={document.body}
                                    isMulti
                                    options={allMembers.map((member) => ({
                                        value: member._id,
                                        label: (
                                            <div
                                                className="member-option"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "10px",
                                                }}
                                            >
                                                <NameProfile userName={member.fullName} width='26px' memberPhoto={member.photo} fontweight='500' />
                                                {member.fullName}
                                            </div>
                                        ),
                                    }))}
                                    onChange={setTeamMembers}
                                /> */}
                                {fieldErrors.teamMembers && (
                                    <span className="error-message">
                                        <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                        {fieldErrors.teamMembers}
                                    </span>
                                )}
                            </div>
                        {/* )} */}
                    </div>
                    <div className="depart-form-btns" style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            justifyContent: uuidToken ? 'flex-end' : 'space-between'
                        }}>

                        {!uuidToken && <button onClick={driveAuthentication} className="dept-form-submit" 
                        style={{display:'flex',alignItems:'center',gap:'10px'}}><img src={`${ASSET_PREFIX_URL}google-drive-logo.png`}
                         alt="" width='20px' height='20px' />Authorize</button>}
                        {/* <button className="dept-form-submit" onClick={handleAuthClick}>Authorize Google Drive</button> */}
                       
                        <button
                        className="dept-form-submit" 
                            onClick={submitForm}
                            disabled={loading} // Disable the button when loading is true
                            style={{
                                opacity: loading ? 0.5 : 1,
                                width:'85px',height:'40px',
                                display:'flex',
                                alignItems:'center',
                                justifyContent:'center' // Apply opacity 0.5 when loading
                            }}
                        >
                           {loading ? <span class="btn-ring"></span>:'Submit'} 
                            
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateWorkspacePopUp;
