import React, { useState, useEffect } from "react";
import "../DepartmentPages/Department.css";
import "./TicketList.css";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import axios from "axios";
import { BASE_URL, POCREATE, PONUMBER, POPTEMPLATE, POVIEW } from "../../constants/config";
import Cookies from "js-cookie";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import DepartmentTicketPopup from "../DepartmentPages/DepartmentTicketPopup";
import Loading from "../Common/Loading/Loading";
import TicketTitlePopUp from "../Common/CommonJsx/TitlePopUp";
import { useTour } from "@reactour/tour";

import NameProfile from "../Common/CommonJsx/NameProfile";
import NoCompanyAddress from "../PopUpsFolder/NoCompanyAddress";
import Pagination from "../Common/CommonJsx/Pagination";
import { debounce } from "@mui/material";
import SearchFilter from "../Common/CommonJsx/SearchFilter";
import { toast } from "react-toastify";


function AllPoList({ type }) {
    const itemsPerPage = 10;
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [length, setLength] = useState(0);
    const [departmentList, setDepartmentList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [addressLength, setAddressLength] = useState(0)
    const [ticketPopUp, setTicketPopUp] = useState(false);
    const [ticketTitle, setTicketTitle] = useState("");
    const [resetButton, setResetButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openAddressPopUp, setOpenAddressPopUp] = useState(false)
    const [hasTemplate,setHasTemplate] = useState(false)
    const [hasNomen,setHasNomen] = useState(false)
    // Check local storage for tour completion status
    // const isTourCompleted = localStorage.getItem('tourCompleted');
    const { setIsOpen, setSteps, isOpen, setCurrentStep } = useTour();
    useEffect(() => {
        // Reset filters when type changes
        setSelectedStatus(null);
        setSearchTerm("");
    }, [type]);
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleNextClick = () => {
        setCurrentStep((prevStep) => prevStep + 1);
    };
    // const handleStatusChange = (selectedOption) => {
    //     setSelectedStatus(selectedOption.value);
    //     setResetButton(true)
    // };
    const handleReset = async () => {

        // setDepartmentId('')

        setSelectedStatus(null)
        setSearchTerm('')
        // setPartAction('')
        setResetButton(false)


        // setReset(false)
    };
    const handlePreviousClick = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    const handleDone = () => {
        setIsOpen(false);
        Cookies.set("ECTourCompleted", true, { expires: 365 });
    };

    const ticketSteps = [
        {
            selector: '[data-tour="step-8"]',
            content: (
                <div>
                    <p>Create your new EC</p>
                    {/* <p>
                Create your new ticket by choosing department and fill the fields
              </p> */}
                    <div className="tour-btns">
                        <button onClick={() => handleNextClick()} className="tour-next">
                            Next
                        </button>
                    </div>
                </div>
            ),
        },
        {
            selector: '[data-tour="step-9"]',
            content: (
                <div>
                    <p>Click to view EC</p>
                    {/* <p>Click to view tickets assigned to you</p> */}
                    <div className="tour-btns">
                        <button onClick={() => handlePreviousClick()} className="tour-back">
                            Back
                        </button>
                        <button onClick={() => handleDone()} className="tour-next">
                            Done
                        </button>
                    </div>
                </div>
            ),
        },
    ];
    const emptyTicketSteps = [
        {
            selector: '[data-tour="step-8"]',
            content: (
                <div>
                    <p>Create your new EC</p>

                    {/* <p>
                Create your new ticket by choosing department and fill the fields
              </p> */}
                    <div className="tour-btns">
                        <button onClick={() => handlePreviousClick()} className="tour-back">
                            Back
                        </button>
                        <button onClick={() => handleDone()} className="tour-next">
                            Done
                        </button>
                    </div>
                </div>
            ),
        },
    ];

    const [hasOpened, setHasOpened] = useState(false);
    // const {member_id}=useParams();

    const nav = useNavigate();
    const { id } = useParams();


    const handleClosePopup = () => {
        setIsPopupOpen(false);

    };
    const handleTitleClick = (event, department) => {
        event.stopPropagation();
        setTicketPopUp(true);
        setTicketTitle(department.data.default_fields_data.purchase_order_title.value);
    };

    const HandleTitleClose = () => {
        setTicketPopUp(false);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1)
        setResetButton(true)
    };

    const allTickets = async () => {

        try {
            setLoading(true);
            const headers = {
                "x-auth-token": localStorage.getItem("token"),
            };
            // let response,


            // if (type === "ec-template") {
            //   endPoint = "ec-template";
            // } if (type === "ec-deviation") {
            //   endPoint = "ec-deviations";
            // }
            const response = await axios.get(
                `${BASE_URL}/v1/purchase-order/po-list`,

                {
                    params: {
                        page: currentPage,
                        limit: itemsPerPage,
                        search: searchTerm,

                    },
                    headers: headers,
                }
            );
            // const orgResponse = await axios.get(BASE_URL + "/v1/setting/get-business-details", { headers });

            // setOrgDetails(response.data.data.business_details)
            const details = response.data.data.po_list;
            // console.log(response.data.data.po_list[0].data.supplier_details.supplier_name.value)
            const paginationDetails = response.data.data.pagination;
            console.log(details);
            setLength(details.length);
            setHasNomen(response.data.data.po_nomen)
            setHasTemplate(response.data.data.po_template)
            setAddressLength(response.data.data.warehouses)
            // console.log(response.data.data.po_nomen,response.data.data.po_template)
            setDepartmentList(details);
            // console.log(paginationDetails)
            setTotalPages(paginationDetails.total_pages);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const handleOpenPoForm = () => {
         if(!hasNomen){
            // nav(PONUMBER)
            toast.info('Please create a PO number')
        }else if(!hasTemplate){
            // nav(POPTEMPLATE)
            toast.info('Please create PO template')
        }else if (!addressLength) {
            toast.info('Please create warehouse to create PO')
        } else {
            nav(POCREATE)
        }
        // setIsPopupFormOpen(!isPopupFormOpen);
        // 
    };
    const handleCloseAddressPopUp = () => {
        setOpenAddressPopUp(!openAddressPopUp)
    }
    useEffect(() => {
        if (searchTerm !== "" || selectedStatus) {
            debounce(allTickets)()
        } else {
            allTickets();
        }
    }, [currentPage, itemsPerPage, type, searchTerm]);



    // const handlePreviousPage = () => {
    //     if (currentPage > 1) {
    //         setCurrentPage(currentPage - 1);
    //     }
    // };

    const handleTicketView = (itemId) => {
        // ec-ticket-view

        //   let navigateTo;
        //   if (type === "ec-template") {
        //     navigateTo = "ec-ticket-view";
        //   } else {
        //     navigateTo = "deviation-ticket-view";
        //   }
        nav(POVIEW(itemId));

        // window.location.pathname = `/ec-ticket-view/${itemId}`;
    };

    const handleHoverTour = () => {
        const tourCompleted = localStorage.getItem("ECTourCompleted");
        if (!tourCompleted) {
            setIsOpen(true);
            setSteps(length > 0 ? ticketSteps : emptyTicketSteps);

            setHasOpened(true);
        }
        if (tourCompleted === true) {
            setIsOpen(false);
        }

        // Check if the tour is closing after being opened
        if (!isOpen && hasOpened) {
            setIsOpen(false);

            Cookies.set("ECTourCompleted", true, { expires: 365 });
        }
    };


    return (
        <>
            {/* <TicketTemplateTopNav/> */}

            <>
                <>
                    <div
                        className="depart-main"
                        style={{ marginTop: "80px" }}
                        onMouseEnter={handleHoverTour}
                    >
                        <>
                            {" "}
                            <div className="spn-depart">
                                {id ? (
                                    <>
                                        <div className="no-ticket-view-right">

                                            <span style={{ color: "#610BEE" }}>EC</span>
                                            <span> Ticket Progress</span>
                                        </div>

                                    </>
                                ) : (
                                    <>
                                        <span>
                                            Purchase orders

                                        </span>
                                    </>
                                )}

                                {/* {length < 1 ? (
                    ""
                  ) : (
                    <> */}

                                <button
                                    className="tkt-btn"
                                    onClick={handleOpenPoForm}
                                    data-tour="step-8"
                                >
                                    <AddOutlinedIcon />


                                    {type === "po" && "New PO"}
                                    {/* New Ticket */}
                                    {/* {type === 'template' ? 'New Ticket' : 'New EC Ticket'} */}
                                </button>
                                {/* </>
                  )} */}
                            </div>
                        </>

                        <div className="tck-fltr">


                            <SearchFilter searchTerm={searchTerm} handleSearchChange={handleSearchChange} listType='POs...' />

                            {resetButton && <button
                                className="reset-btn"
                                onClick={handleReset}
                                data-tour="step-11"
                            >
                                reset <TuneOutlinedIcon />
                            </button>}
                            {/* </>
            )} */}

                            {/* {id ? '' : <> */}
                        </div>
                       
                                    <div className="department-table">
                                        <table className="department-table-data">
                                            <thead>
                                                <tr>
                                                   
                                                    <th className="depart-header">Supplier name </th>
                                                    <th className="depart-header">PO number </th>
                                                  
                                                    <th className="tck-header">PO status</th>
                                                    <th className="tck-header">Approver status</th>
                                                    <th className="tck-header">Created by</th>
                                                </tr>
                                            </thead>
                                            {loading ? <Loading/> : <tbody>
                                                {departmentList.map((department, item) => (
                                                    <tr
                                                        key={item}
                                                        onClick={() =>
                                                            handleTicketView(department.purchase_order_nomenclature)
                                                        }
                                                        style={{ cursor: "pointer" }}
                                                        data-tour="step-9"
                                                    >
                                                        {/* <td>{department.sequence_id}</td> */}


                                                        <td title={department.supplier_id.data.default_fields_data.supplier_name.value}>
                                                            {department.supplier_id.data.default_fields_data.supplier_name.value.length > 30 ? (
                                                                <>
                                                                    {department.supplier_id.data.default_fields_data.supplier_name.value.slice(0, 30)}
                                                                    ...
                                                                    <button className="view-more-text" onClick={(event) => handleTitleClick(event, department)}>
                                                                        view
                                                                    </button>
                                                                </>
                                                            ) : (
                                                                department.supplier_id.data.default_fields_data.supplier_name.value
                                                            )}
                                                        </td>
                                                        <td>{department.purchase_order_nomenclature}</td>

                                                        {/* <td>{department.data.defaultFields.supplier_reference_invoice.value}</td> */}
                                                        <td>{department.asset_po_status}</td>
                                                        <td>{department.data.default_fields.status.value}</td>



                                                        
                                                        <td>
                                                            <div
                                                                className="member-option"
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    gap: "10px",
                                                                }}
                                                            >
                                                                <NameProfile
                                                                    userName={
                                                                        department.created_by.fullName
                                                                    }
                                                                    memberPhoto={
                                                                        department.created_by.photo
                                                                    }
                                                                    width="26px"
                                                                    fontweight="500"
                                                                />

                                                                <span className="member-fullname">
                                                                    {
                                                                        department.created_by.fullName
                                                                    }
                                                                </span>
                                                            </div>
                                                        </td>
                                                        


                                                    </tr>
                                                ))}
                                            </tbody>}
                                            
                                        </table>
                                    </div>
                               

                      
                        {(totalPages > 1 && !loading) && (
                            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
                        )}
                        
                    </div>
                    {isPopupOpen && (
                        <DepartmentTicketPopup onClose={handleClosePopup} type={type} />
                    )}
                    
                    {ticketPopUp && (
                        <TicketTitlePopUp
                            header='PO title'
                            onClose={HandleTitleClose}
                            ticketTitle={ticketTitle}
                        />
                    )}
                    {openAddressPopUp && <NoCompanyAddress onclose={handleCloseAddressPopUp} />}
                    {/* {isPopupFormOpen &&

                        <SupplyPageForm onClose={handleOpenPoForm} templateType={type} />
                    }
                    {noTemplate && <NoTemplatePopUp onclose={handeleclose} />} */}
                </>
            </>
        </>
    )
}

export default AllPoList