import React, { useState, useEffect, useRef } from "react";
import { BASE_URL, PHOTO_LINK, WAREHOUSEVIEW } from "../../constants/config";
import Loading from "../Common/Loading/Loading";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Pagination from "../Common/CommonJsx/Pagination";
import NameProfile from "../Common/CommonJsx/NameProfile";
import SearchFilter from "../Common/CommonJsx/SearchFilter";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./TicketList.css";
import WareHouse from './../Warehouse/WareHouse';
import TicketTitlePopUp from "../Common/CommonJsx/TitlePopUp";
import { toast } from "react-toastify";

function WareHouseList() {
  const [warehouses, setWarehouses] = useState([]); // Store the warehouse data
  const [currentPage, setCurrentPage] = useState(1); // Pagination state
  const [totalPages, setTotalPages] = useState(1); // Total pages
  const [loading, setLoading] = useState(false); // Loading state
  const [searchTerm, setSearchTerm] = useState(""); // Search filter state
  const [resetButton, setResetButton] = useState(false); // Reset button visibility
  const [ticketPopUp, setTicketPopUp] = useState(false);
  const [ticketTitle, setTicketTitle] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const nav = useNavigate();
  const [createWarehouse, setCreateWarehouse] = useState(false);
  const [warehouseTemplate, setWarehousetemplate] = useState(false);

  const handleWareHouseList = async () => { 
    try {
      setLoading(true);
      const headers = {
        "x-auth-token": localStorage.getItem("token"),
      };

      const response = await axios.get(
        `${BASE_URL}/v1/warehouse/get-warehouse-list`,
        {
          params: {
            page: currentPage,
            limit: 10,
            search: searchTerm,
          },
          headers: headers,
        }
      );
      setWarehousetemplate(response.data.data.warehouse_temp)
      const details = response.data.data.warehouse_list;
      const paginationDetails = response.data.data.pagination;
      setWarehouses(details);
      setTotalPages(paginationDetails.total_pages);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    handleWareHouseList();
  }, [currentPage, searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1)
    setResetButton(true);
  };

  const handleReset = () => {
    setSearchTerm("");
    setResetButton(false);
    setCurrentPage(1);
  };

  const openCreatePartPopUp = () => {
    if(!warehouseTemplate){
      toast.error('Create warehouse template before creating Warehouse')
    }else{
      setCreateWarehouse(!createWarehouse);
    }
    
  };
  const handleDescriptionClick = (event, description) => {
    event.stopPropagation();
    setTicketPopUp(true);
    setTicketTitle(description);
  };
  return (
    <>
      <div className="depart-main" style={{ marginTop: "80px" }}>
        <div className="part-number-list-title">
          <span>Warehouses</span>
          <div className="part-number-list-btns">
            <button onClick={openCreatePartPopUp}>
              <AddOutlinedIcon />
              Create new warehouse
            </button>
          </div>
        </div>

        <div className="tck-fltr">
          <SearchFilter
            searchTerm={searchTerm}
            handleSearchChange={handleSearchChange}
            listType="warehouse..."
          />
          {resetButton && (
            <button className="reset-btn" onClick={handleReset} data-tour="step-11">
              reset <TuneOutlinedIcon />
            </button>
          )}
        </div>

        
              <div className="department-table">
                <table className="department-table-data">
                  <thead>
                    <tr>
                      <th className="depart-header">ID</th>
                      <th className="depart-header">Warehouse name</th>
                      <th className="tck-header">Manager</th>
                      <th className="tck-header">Address</th>
                    </tr>
                  </thead>
                  {loading ? <Loading/> : <tbody>
                    {warehouses.map((warehouse) => {
                      const assignedManagers = warehouse.warehouse_default_data.assigned_warehouse_managers.value || [];
                      const visibleManagers = assignedManagers.slice(0, 4);
                      const hiddenManagers = assignedManagers.slice(4);

                      return (
                        <tr key={warehouse._id} onClick={()=>nav(WAREHOUSEVIEW(warehouse.warehouse_sequence_id))} style={{height:'70px'}}>
                          <td>{warehouse.warehouse_sequence_id}</td>
                          <td>{warehouse.warehouse_default_data.warehouse_name.value}</td>
                          <td className="part-number-photo">
                            <div className="members-photo-details" data-tour="step-10">
                              {visibleManagers.map((manager) => (
                                <div key={manager.member_id} className="member-item">
                                  {manager.photo ? (
                                    <img
                                      src={PHOTO_LINK + manager.photo}
                                      alt=''
                                      style={{
                                        borderRadius: "50%",
                                        width: "32px",
                                        height: "32px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  ) : (
                                    <NameProfile
                                      userName={manager.fullName}
                                      width="32px"
                                      fontweight="500"
                                      borderRadius="50%"
                                    />
                                  )}
                                  <span className="member-name">{manager.fullName}</span>
                                </div>
                              ))}

                              {hiddenManagers.length > 0 && (
                                <div className="member-item">
                                  <button onClick={toggleDropdown} className="show-more-button">
                                    +{hiddenManagers.length}
                                  </button>
                                  {showDropdown && (
                                    <div className="members-drop-div">
                                      {hiddenManagers.map((manager) => (
                                        <div key={manager.member_id} className="members-dropdown">
                                          <NameProfile
                                            userName={manager.fullName}
                                            memberPhoto={manager.photo}
                                            width="32px"
                                            fontweight="500"
                                          />
                                          <span>{manager.fullName}</span>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </td>
                          <td> {warehouse.warehouse_default_data.warehouse_address.value && warehouse.warehouse_default_data.warehouse_address.value.length > 30 ? (
                          <>
                            {warehouse.warehouse_default_data.warehouse_address.value.slice(
                              0,
                              24
                            )}
                            ...
                            <button
                              className="view-more-text"
                              onClick={(event) =>
                                handleDescriptionClick(event, warehouse.warehouse_default_data.warehouse_address.value)
                              }
                            >
                              view
                            </button>
                          </>
                        ) : (
                          warehouse.warehouse_default_data.warehouse_address.value
                        )}</td>
                        </tr>
                      );
                    })}
                  </tbody>}
                  
                </table>
              </div>
            

        {(totalPages > 1 && !loading)  && (
          <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
        )}
      </div>
      {createWarehouse && <WareHouse handleClose={() => setCreateWarehouse(!createWarehouse)} />}
      {(ticketPopUp) && (
        <TicketTitlePopUp
          header="Warehouse address"
          onClose={()=>setTicketPopUp(!ticketPopUp)}
          ticketTitle={ticketTitle}
        />
      )}
    </>
  );
}

export default WareHouseList;
