import React, { useState, useCallback } from 'react';
import Select from "react-select";
import axios from "axios";
import debounce from "lodash.debounce";
import { toast } from 'react-toastify';
import { BASE_URL, HEADERS } from '../../constants/config';

function DesignDropdown({ field, handleDesignSelectChange }) {
    const [designs, setDesigns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(""); // Search query
    const [isMenuOpen, setIsMenuOpen] = useState(false); // Track dropdown open/close state
    const limit = 10;

    // Fetch designs from the server
    const fetchDesigns = async (pageNumber = 1, searchQuery = "") => {
        if (loading || (!hasMore && pageNumber > 1)) return;
        setLoading(true);
        try {
            const designResponse = await axios.get(BASE_URL + "/v1/pdm/get-designs-parts", {
                params: {
                    page: pageNumber,
                    limit,
                    search: searchQuery, // Include search query in API call
                },
                headers: HEADERS,
            });
            if (!designResponse.data?.meta?.success) {
                toast.error(designResponse.data?.meta.message);
            } else {
                const fetchedDesigns = designResponse.data.data.designs;
                if (fetchedDesigns.length === 0) {
                    setHasMore(false); // Stop fetching when no more designs are available
                } else {
                    setDesigns((prevDesigns) =>
                        pageNumber === 1 ? fetchedDesigns : [...prevDesigns, ...fetchedDesigns]
                    );
                    setPage(pageNumber + 1); // Increase page number for the next fetch
                }
            }
        } catch (error) {
            console.error("Error fetching designs:", error);
        } finally {
            setLoading(false);
        }
    };

    // Debounced function for search
    const handleSearchChange = useCallback(
        debounce((inputValue) => {
            setSearch(inputValue);
            setPage(1); // Reset page to 1 for new search
            setHasMore(true); // Allow fetching new results
            fetchDesigns(1, inputValue); // Fetch designs for the search input
        }, 300),
        []
    );

    // Handle dropdown open state to prevent unnecessary API calls
    const handleMenuOpen = () => {
        if (!isMenuOpen) {
            setIsMenuOpen(true);
            if (designs.length === 0) fetchDesigns(1, search); // Fetch initial designs if not already fetched
        }
    };

    // Handle dropdown close state
    const handleMenuClose = () => {
        setIsMenuOpen(false); // Mark the dropdown as closed
    };

    // Handle dropdown scroll to bottom for infinite scrolling
    const handleMenuScrollToBottom = () => {
        if (hasMore && isMenuOpen) {
            fetchDesigns(page, search); // Fetch next page with current search query
        }
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: "340px",
            height: '40px',
            backgroundColor: "transparent",
            border: "1px solid #EDF2F7",
            boxShadow: "none",
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),
        option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? "transparent" : "white",
            color: state.isSelected ? "black" : "inherit",
            cursor: "pointer",
            ":hover": {
                background: "lightgray",
            },
        }),
    };

    return (
        <Select
            placeholder={field ? field.placeholder : "Search designs..."}
            styles={customStyles}

            isMulti={false}
            options={designs.map((design) => ({
                value: design._id,
                label: `${design.name}-v${design.latest_version.version.major}.${design.latest_version.version.minor}`,
                design,
            }))}
            onChange={(selectedOption) => {
                handleDesignSelectChange(selectedOption.design);
            }}
            value={
                field && field.design
                    ? {
                        value: field.design._id,
                        label: `${field.design.name}-v${field.design.latest_version.version.major}.${field.design.latest_version.version.minor}`,
                        design: field.design,
                    }
                    : null
            }
            onInputChange={(inputValue) => handleSearchChange(inputValue)} // Trigger search on input change
            onMenuScrollToBottom={handleMenuScrollToBottom} // Load more options on scroll
            onMenuOpen={handleMenuOpen} // Set menu open state and fetch initial data
            onMenuClose={handleMenuClose} // Set menu close state
            isLoading={loading}
        />
    );
}

export default DesignDropdown;
