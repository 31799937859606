import React, { useState, useEffect } from "react";
import "../DepartmentPages/Department.css";
import "./TicketList.css";
import Select from "react-select";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import axios from "axios";
import { BASE_URL, MBOMVIEW, PCIVIEW } from "../../constants/config";
import Cookies from "js-cookie";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Loading from "../Common/Loading/Loading";
import TicketTitlePopUp from "../Common/CommonJsx/TitlePopUp";
import NameProfile from "../Common/CommonJsx/NameProfile";
import DateFormateForList from "../Common/CommonJsx/DateFormateForList";
import Pagination from "../Common/CommonJsx/Pagination";
import EcPageForm from "../EcPages/EcPageForm";
import { debounce } from "../../constants/helper";
import SearchFilter from "../Common/CommonJsx/SearchFilter";


const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "30px",
      width: "200px",
      border: "1px solid #EDF2F7",
      padding: "0px 15px",
      boxSizing: "border-box",
      height: "40px",
    }),
  };

function PcImplementationList() {
    const itemsPerPage = 10;
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [length, setLength] = useState(0);
    const [BomtList, setMbomtList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const [ticketPopUp, setTicketPopUp] = useState(false);
    const [ticketTitle, setTicketTitle] = useState("");
    const [resetButton, setResetButton] = useState(false);
    const [loading, setLoading] = useState(false);
   


    useEffect(() => {
        // Reset filters when type changes
        setSelectedStatus(null);
        setSearchTerm("");
    }, []);



    const handleReset = async () => {
        setSelectedStatus(null)
        setSearchTerm('')
        setResetButton(false)
    };








    const nav = useNavigate();
    const { id } = useParams();


    const handleClosePopup = () => {
        setIsPopupOpen(!isPopupOpen);

    };
    const handleTitleClick = (event, department) => {
        event.stopPropagation();
        setTicketPopUp(true);
        setTicketTitle(department.data.default_fields_data.purchase_order_title.value);
    };

    const HandleTitleClose = () => {
        setTicketPopUp(false);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1)
        setResetButton(true)
    };

    const allTickets = async () => {

        try {
            setLoading(true);
            const headers = {
                "x-auth-token": localStorage.getItem("token"),
            };

            const response = await axios.get(
                `${BASE_URL}/v1/manufac-bom/get-mbom-list`,
                {
                    params: {
                        page: currentPage,
                        limit: itemsPerPage,
                        search: searchTerm,
                        product_status:selectedStatus

                    },
                    headers: headers,
                }
            );
            const details = response.data.data.mbom_product_list;
            const paginationDetails = response.data.data.pagination;
            setLength(details.length);
            setMbomtList(details);
            setTotalPages(paginationDetails.total_pages);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const handleOpenPoForm = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    useEffect(() => {
        if (searchTerm !== "" || selectedStatus) {
            debounce(allTickets)()
        } else {
            allTickets();
        }
    }, [currentPage, itemsPerPage, searchTerm,selectedStatus]);





    const handleTicketView = (itemId) => {
        
        nav(PCIVIEW(itemId));


    };

    const handleStatusChange = (selectedOption) => {
        setSelectedStatus(selectedOption.value);
        setResetButton(true)
      };

    const statusOptions = ['Draft', 'Pending Approval', 'Released'];
    return (
        <>
            {/* <TicketTemplateTopNav/> */}

            <>
                <>
                    <div
                        className="depart-main"
                        style={{ marginTop: "80px" }}

                    >
                        <>
                            {" "}
                            <div className="spn-depart">
                                {id ? (
                                    <>
                                        <div className="no-ticket-view-right">

                                            <span style={{ color: "#610BEE" }}>EC</span>
                                            <span> Ticket Progress</span>
                                        </div>

                                    </>
                                ) : (
                                    <>
                                        <span>
                                           Production change implementations

                                        </span>
                                    </>
                                )}

                                {/* {length < 1 ? (
                    ""
                  ) : (
                    <> */}

                                <button
                                    className="tkt-btn"
                                    onClick={handleOpenPoForm}
                                    data-tour="step-8"
                                >
                                    <AddOutlinedIcon />


                                    New  PCI
                                </button>

                            </div>
                        </>

                        <div className="tck-fltr">

                           
                            <SearchFilter searchTerm={searchTerm} handleSearchChange={handleSearchChange} listType='PCIs'/>
                            <Select
                                styles={customStyles}
                                placeholder={selectedStatus === null ? "status" : ""}
                                options={statusOptions.map((option) => ({
                                    value: option,
                                    label: option,
                                }))}
                                onChange={handleStatusChange}
                                value={selectedStatus ? { value: selectedStatus, label: selectedStatus } : null}
                            // onChange={(selectedOption) => setSelectedDepartment(selectedOption)}
                            />
                            {resetButton && <button
                                className="reset-btn"
                                onClick={handleReset}
                                data-tour="step-11"
                            >
                                reset <TuneOutlinedIcon />
                            </button>}
                            {/* </>
            )} */}

                            {/* {id ? '' : <> */}
                        </div>
                       
                                    <div className="department-table">
                                        <table className="department-table-data">
                                            <thead>
                                                <tr>
                                                    <th className="id-header">ID</th>
                                                    <th className="depart-header">Product name </th>
                                                    <th className="depart-header">Title </th>

                                                    <th className="tck-header">status</th>
                                                    <th className="tck-header">Created by</th>
                                                    <th className="tck-header">Updated at</th>
                                                </tr>
                                            </thead>
                                            {loading ? <Loading/> : <tbody>
                                                {BomtList.map((item, index) => (
                                                    <tr
                                                        key={index}
                                                        onClick={() =>
                                                            handleTicketView(item.pci_sequence_id)
                                                        }
                                                        style={{ cursor: "pointer" }}
                                                        data-tour="step-9"
                                                    >

                                                        <td>{item.pci_sequence_id}</td>
                                                        <td>{item.product_name}</td>
                                                        <td title={item.data.default_fields_data.title.value}>
                                                            {item.data.default_fields_data.title.value.length > 30 ? (
                                                                <>
                                                                    {item.data.default_fields_data.title.value.slice(0, 30)}
                                                                    ...
                                                                    <button className="view-more-text" onClick={(event) => handleTitleClick(event, item)}>
                                                                        view
                                                                    </button>
                                                                </>
                                                            ) : (
                                                                item.data.default_fields_data.title.value
                                                            )}
                                                        </td>
                                                        <td>{item.product_approve_status}</td>


                                                        <td>
                                                            <div
                                                                className="member-option"
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    gap: "10px",
                                                                }}
                                                            >
                                                                <NameProfile
                                                                    userName={
                                                                        item.memberDetails.fullName
                                                                    }
                                                                    memberPhoto={
                                                                        item.memberDetails.photo
                                                                    }
                                                                    width="26px"
                                                                    fontweight="500"
                                                                />

                                                                <span className="member-fullname">
                                                                    {
                                                                        item.memberDetails.fullName
                                                                    }
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <DateFormateForList formateTime={item.updatedAt} />
                                                        </td>




                                                        
                                                    </tr>
                                                ))}
                                            </tbody> }
                                           
                                        </table>
                                    </div>
                                


                        {(totalPages > 1 && !loading)  && (
                            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
                        )}

                    </div>


                    {ticketPopUp && (
                        <TicketTitlePopUp
                        header='PCI title'
                            onClose={HandleTitleClose}
                            ticketTitle={ticketTitle}
                        />
                    )}
                    {isPopupOpen && (
                        <EcPageForm onClose={handleClosePopup} templateType='pci-view'/>
                    )}

                </>
            </>
        </>
    )
}

export default PcImplementationList